.container {
  margin: 0 auto;
  max-width: $containerWidth;
  width: 100%;

  &.flex {
    height: 100%;
    @include displayFlex;
  }
}
