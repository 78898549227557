.about {
  @include responsive(phone) {
    // padding: 0 2em;
  }
  &__main {
    padding: 10em 0;
    @include responsive(phone) {
      padding: 5em 0 0;
    }
  }
  &__banner {
    background-color: #fbfbfb;
    width: 100%;

    &__title {
      padding: 2em;
      text-align: center;
      font-size: 3.2em;

      @include responsive(phone) {
        font-size: 2.3em;
      }
    }

    &__photo {
      height: 800px;
      width: 100%;
      @include responsive(phone) {
        height: 200px;
      }
      & img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin: 0 auto;
      }
    }
  }

  &__text {
    padding: 3em;
    text-align: center;
  }

  &__statement {
    margin-bottom: 6em;
    @include responsive(phone) {
      text-align: center;
      margin-bottom: 0em;
      padding: 3em;
    }
    & .title {
      font-size: 2.8em;
      margin-bottom: 1em;
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        @include responsive(phone) {
          display: none !important;
        }
      }
      &::before {
        display: block;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: $primaryColor;
        left: -30px;
        top: 15px;
      }

      &::after {
        height: 313%;
        width: 2px;
        background-color: $primaryColor;
        left: -24px;
        top: 30px;
      }
    }

    &.vision {
      & .title {
        &::after {
          display: none;
        }
      }
    }
  }

  &__values {
    display: flex;
    padding: 6em 0;
    @include responsive(phone) {
      flex-direction: column;
      text-align: center;
      padding: 3em;
    }
    & .title {
      font-size: 2.8em;
      flex: 20%;
      @include responsive(phone) {
        margin-bottom: 1em;
      }
    }
    &__list {
      flex: 80%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 3em;

      @include responsive(phone) {
        grid-template-columns: repeat(1, 1fr);
      }

      & .value {
        @include responsive(phone) {
          padding: 2em 4em;
        }
        & .icon {
          height: 60px;
          width: 60px;
          background-color: $primaryColor;
          border-radius: 4px;
          margin-bottom: 0.8em;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $colorWhite;
          font-size: 2.4em;
          @include responsive(phone) {
            margin: 0 auto 0.8em;
            border-radius: 50%;
          }
        }
        & .title {
          font-size: 1.8em;
          margin-bottom: 1em;
        }
      }
    }
  }
}
