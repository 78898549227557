.services {
  @include responsive(tabletLandscape) {
    padding: 0 3em;
  }
  @include responsive(phone) {
    padding: 0 2em;
  }
  &__main {
    padding: 10em 0;
    @include responsive(phone) {
      padding: 5em 0;
    }
  }

  &__text {
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include responsive(phone) {
      padding: 0 3em;
    }

    & .service:nth-child(odd) {
      & .service__icon {
        animation: move-in-left 3000ms ease-in-out;
        @include responsive(phone) {
          flex: none;
          height: 150px;
        }
      }
      .service__content {
        margin-left: 10em;
      }
    }

    & .service:nth-child(even) {
      flex-direction: row-reverse;

      @include responsive(phone) {
        flex-direction: column-reverse;
      }

      & .service__content {
        margin-right: 10em;
      }

      & .service__icon {
        animation: move-in-right 3000ms ease-in-out;
        @include responsive(phone) {
          animation: move-in-left 3000ms ease-in-out;
        }
        [data-content]:before {
          right: -1.2em;
          left: auto;

          @include responsive(tabletLandscape) {
            right: -2.5em;
          }
        }
      }
    }

    & .service {
      display: flex;
      flex-direction: row;

      @include responsive(phone) {
        flex-direction: column-reverse;
        margin-bottom: 7em;
        min-height: 300px;
        text-align: center;
      }

      &__icon {
        flex: 35%;
        background-repeat: no-repeat;
        background-size: contain;
        height: 400px;

        @include responsive(phone) {
          height: 150px;
          margin-top: 7em;
          flex: none;
          background-position: center;
        }

        h1 {
          position: relative;
          span {
            display: none;
          }
        }

        [data-content]:before {
          content: attr(data-content);
          color: $darkAccent;
          position: absolute;
          font-size: 7em;
          left: -1.2em;
          top: -1em;

          @include responsive(tabletLandscape) {
            font-size: 4em;
            position: relative;
            left: -0.3em;
            top: -1.3em;
          }

          @include responsive(phone) {
            content: "";
          }
        }
      }

      &__content {
        flex: 60%;

        @include responsive(phone) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        .title {
          margin-bottom: 1em;
          font-size: 2.4em;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: -20px;
            height: 3px;
            width: 7%;
            background-color: $primaryColor;

            @include responsive(phone) {
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
