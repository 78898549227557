.contact {
  &__main {
    padding: 10em 0;
    @include responsive(tabletLandscape) {
      padding: 5em 3em;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3em;
    padding-bottom: 5em;
    @include responsive(tabletLandscape) {
      padding: 0 3em;
      grid-template-columns: repeat(2, 1fr);
    }
    @include responsive(tabletPortrait) {
      grid-template-columns: repeat(1, 1fr);
    }
    @include responsive(phone) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0 3em;
    }
    &__content {
      padding: 5em;
      background-color: #fbfbfb;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      border-radius: $borderRadiusWidth;

      &.email {
        @include responsive(tabletLandscape) {
          grid-column: 1/3;
        }
        @include responsive(tabletPortrait) {
          grid-column: 1;
        }
      }

      transition: all 400ms ease;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
        & .icon,
        & .text {
          color: $darkMedium;
        }
      }
      & .icon,
      & .text {
        color: $darkLight;
        transition: color 400ms ease;
      }

      & .icon {
        font-size: 3.6em;
        margin-bottom: 1em;
      }

      & .text {
        display: block;
        font-size: 1.6em;
      }
    }
  }
  &__form {
    @include responsive(tabletLandscape) {
      padding: 3em;
    }
    &__title {
      font-size: 2.8em;
      margin-bottom: 1em;
    }
    &__form-area {
      background-color: $darkAccent;
      border-radius: $borderRadiusWidth;
      padding: 3em;
      display: grid;
      gap: 2em;

      @include responsive(tabletPortrait) {
        grid-template-columns: repeat(1, 1fr);
      }

      & .form-row {
        grid-column: 1;

        & .form-elem {
          position: relative;
          & label {
            margin-bottom: 0.5em;
            display: block;
            font-size: 1.5em;
            font-weight: 600;
            color: $darkMedium;
          }

          & .form-input {
            position: relative;
            height: 50px;
            border: 1px solid transparent;
            border-radius: $borderRadiusWidth;
            &.error {
              border-color: $colorRed;
            }
            & .error-message {
              &.show {
                display: block;
              }
              color: $colorRed;
              font-size: 1.35em;
              display: none;
            }
            &__icon {
              position: absolute;
              width: 48px;
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              font-size: 1.8em;
              color: $darkLight;
            }
            & input,
            & textarea {
              width: 100%;
              height: 100%;
              padding-left: 3em;
              font-size: 1.6em;
              border: none;
              outline: none;
              font-family: $textFont;
              border-radius: $borderRadiusWidth;
            }
            & textarea {
              padding: 1.5em;
              min-height: 254px;
              height: 100%;
              resize: none;
              @include responsive(phone) {
                min-height: 50px;
              }
            }
          }
        }
      }

      & .message {
        grid-row: 1 / span 3;
        grid-column: 2;
        height: 100%;

        & .form-input {
          min-height: 256px;
        }

        @include responsive(tabletPortrait) {
          grid-row: 4;
          grid-column: 1;
        }
      }
    }
    &__action {
      margin: 3em auto 7em;
      max-width: 300px;
      width: 100%;
    }
  }
}
