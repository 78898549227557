.animate {
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2500ms;
}
.animate-110 {
  animation-name: animate-110;
}

.animate-112 {
  animation-name: animate-112;
}

.animate-113 {
  animation-name: animate-113;
}

.animate-114 {
  animation-name: animate-114;
}

.animate-116 {
  animation-name: animate-116;
}

.animate-118 {
  animation-name: animate-118;
}

.animate-137 {
  animation-name: animate-137;
}

@keyframes move-in-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes move-in-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes animate-110 {
  0% {
    transform: skewX(1);
  }
  100% {
    transform: skewX(0);
  }
}

@keyframes animate-112 {
  0% {
    transform: translate(493.491px, 205.014px);
  }
  100% {
    transform: translate(523.491px, 255.014px);
  }
}

@keyframes animate-113 {
  0% {
    transform: translate(243.832px, 276.014px);
  }
  100% {
    transform: translate(243.832px, 306.014px);
  }
}

@keyframes animate-114 {
  0% {
    transform: translate(27.001px, 221.354px);
  }
  100% {
    transform: translate(-27.001px, 221.354px);
  }
}

@keyframes animate-116 {
  0% {
    transform: translate(516.923px, 0);
  }
  100% {
    transform: translate(536.923px, -30px);
  }
}

@keyframes animate-118 {
  0% {
    transform: translate(0.001px, 123.706px);
  }
  100% {
    transform: translate(-30.001px, 123.706px);
  }
}

@keyframes animate-137 {
  0% {
    transform: translate(528.851px, 69.023px);
  }
  100% {
    transform: translate(538.851px, 39.023px);
  }
}
