.big-hero {
  height: 70em;
  width: 100%;
  background-image: url(../../assets/images/mb-bridge.svg);
  background-repeat: no-repeat;
  background-position: 20% 100%;

  @include responsive(tabletPortrait) {
    background-image: none;
    height: auto;
  }

  &__flex {
    display: flex;
    flex-direction: row;
    padding-top: $headerHeight;
    @include responsive(tabletLandscape) {
      padding-left: 3em;
      padding-right: 3em;
    }
    @include responsive(tabletPortrait) {
      flex-direction: column;
    }
    @include responsive(phone) {
      padding: 4em 2em 0;
    }
  }

  &__left {
    flex: 50%;
    @include responsive(tabletPortrait) {
      flex: 100%;
    }
  }
  &__right {
    flex: 50%;
    @include responsive(tabletPortrait) {
      flex: 100%;
    }
  }

  &__text {
    padding: 10em 0;
    width: 100%;
    @include responsive(tabletPortrait) {
      text-align: center;
      padding-left: 2em;
      padding-right: 2em;
    }
    @include responsive(phone) {
      padding: 0;
    }
    & h1 {
      font-family: $titleFont;
      font-size: 3.4em;
      color: $primaryColor;
      @include responsive(phone) {
        font-size: 2.8em;
      }
    }
    & p {
      font-family: $textFont;
      font-size: 1.6em;
      color: $darkLight;
    }
  }

  &__photo {
    height: 50em;
    width: 100%;
    margin-top: 7em;
    @include responsive(tabletPortrait) {
      height: 45em;
      margin-top: 3em;
    }
    @include responsive(phone) {
      margin-top: 0px;
      height: 40em;
      width: 80%;
      margin-top: 0em;
      margin-left: auto;
      margin-right: auto;
    }
    & img,
    & svg {
      height: 100%;
      width: 100%;
    }
  }

  &__svg-curve {
    display: none;
    margin-top: 60px;
    @include responsive(tabletPortrait) {
      margin-top: -20px;
      display: none;
    }
    @include responsive(tabletLandscape) {
      margin-top: 50px;
    }
    @include responsive(phone) {
      margin-top: -59px;
      display: none;
    }
  }
}
