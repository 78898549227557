.home {
  &__partners {
    margin: 10em 0;
    width: 100%;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    @include responsive(phone) {
      margin: 5em 0;
    }
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5em 0;
      @include responsive(phone) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 2em;
      }
    }
    &__title {
      font-size: 1.8em;
      font-weight: 100;
      color: $darkMedium;
      @include responsive(tabletPortrait) {
        display: none;
      }
    }
    &__logo {
      &#neco {
        height: 8em;
        grid-row: 1/3;
        @include responsive(phone) {
          height: 6em;
        }
      }
      height: 3em;
      width: 250px;
      @include responsive(phone) {
        margin-bottom: 2em;
        height: 3em;
        width: auto;
      }
      & img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__summary {
    padding: 3em 0;
    &__contents {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2em;
      @include responsive(tabletPortrait) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include responsive(phone) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    & .summary-content {
      border-bottom: 1px solid #ededed;
      position: relative;
      padding: 2em;

      @include responsive(phone) {
        text-align: center;
        padding: 4em;
      }

      & .border-right {
        border-right: solid 1px #ededed;
        position: absolute;
        right: -1em;
        top: 0;
        height: calc(100% - 2em);

        @include responsive(phone) {
          border-right: none;
        }
      }

      &__icon {
        height: 150px;
        width: 100%;
        & img {
          width: auto;
          height: 100%;
          object-fit: contain;
        }
      }
      h3 {
        font-size: 1.6em;
        margin-bottom: 1em;
      }

      p {
        font-size: 1.6em;
        line-height: 1.8em;
        color: $darkLight;
      }
    }
    & .summary-content:nth-child(3n) {
      border-right: none;
    }
    & .summary-content:nth-last-child(-n + 3) {
      border-bottom: none;
      @include responsive(tabletPortrait) {
        border-bottom: 1px solid #ededed;
      }
    }
    & .view-more {
      text-align: center;
      padding: 3em 0;
      a {
        color: $primaryColor;
        font-size: 1.5em;
        font-weight: 700;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__we-got-you {
    padding: 3em 0;
    @include responsive(tabletLandscape) {
      padding: 3em;
    }
    & > .container {
      display: flex;
      @include responsive(tabletPortrait) {
        flex-direction: column;
      }
    }
    @include responsive(phone) {
      padding: 2em 0;
    }
    &__text,
    &__image {
      flex: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__text {
      @include responsive(tabletPortrait) {
        text-align: center;
      }
      @include responsive(phone) {
        text-align: center;
      }
      h1 {
        line-height: 1em;
        top: -12px !important;
        @include responsive(phone) {
          font-size: 5em !important;
        }
      }
    }
    &__image {
      svg {
        width: 100%;
        @include responsive(tabletPortrait) {
          width: 70%;
        }
        @include responsive(phone) {
          width: 80%;
        }
      }
    }
  }
}
