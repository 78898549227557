body {
  font-family: $textFont;
  font-weight: 400;
  line-height: 1.7;
}

p {
  font-family: $textFont;
}

// text-contents
.text-content {
  text-align: center;
  &__subheader {
    font-size: 4em;
    margin: 4em 0 1em;
    @include responsive(phone) {
      font-size: 2.4em;
      margin: 2em 0 1em;
    }
  }
}

// Headings
.header-primary {
  font-size: 5rem;
  line-height: 1.3;
}

.header-secondary {
  font-size: 4em;
}

.header-tertiary {
  font-size: 2.4em;
}

.has-shadow-text {
  position: relative;
  color: $darkMedium;
  h4 {
    font-size: 2.4em;
    @include responsive(phone) {
      font-size: 2.2em;
    }
  }
  & .shadow-text {
    @include responsive(phone) {
      display: none;
    }
    &.align-center {
      left: 0;
      right: 0;
      text-align: center;
    }
    position: absolute;
    font-size: 5em;
    color: $darkAccent;
    top: -26px;
    z-index: -1;
    @include responsive(tabletPortrait) {
      left: 0;
      right: 0;
      margin: 0 auto;
      font-size: 6em;
      top: -0.6em;
    }
    @include responsive(phone) {
      font-size: 4em;
      top: -0.4em;
    }
  }
}
