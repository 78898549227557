.u-text-center {
  text-align: center;
}

.u-right-text {
  text-align: right;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem;
}

.u-margin-bottom-medium {
  margin-bottom: 3rem;
}

.u-margin-bottom-large {
  margin-bottom: 6rem;
  @include responsive(phone) {
    margin-bottom: 3em;
  }
}

.u-display-flex {
  display: flex;
  @include responsive(phone) {
    flex-direction: column;
  }
}

.u-full-width {
  width: 100%;
}
