.footer {
  background: $darkAccent;
  padding: 2em 0 4em;

  &__logo {
    height: 70px;
    width: 200px;
    margin: 0 auto 2em;

    & img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4em;
    @include responsive(phone) {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 2em;
    }

    & li {
      font-size: 1.4em;
      @include responsive(phone) {
        margin-bottom: 1em;
      }
      a {
        color: $darkDark;
      }
      span.dot {
        padding-left: 3.5em;
        padding-right: 3.5em;
        @include responsive(phone) {
          display: none;
        }
      }
    }
  }

  &__end {
    font-size: 1.3em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include responsive(tabletLandscape) {
      flex-direction: column;
      &__socials {
      }
    }

    &__copyright,
    &__up {
      flex: 40%;
    }

    &__copyright {
      font-size: 1.2em;
      @include responsive(tabletLandscape) {
        margin-bottom: 2em;
      }
    }

    &__socials {
      width: 160px;
      background-color: #e4e9ec;
      border-radius: 6em;
      padding: 1em;
      display: flex;
      justify-content: space-evenly;
      @include responsive(tabletLandscape) {
        margin-bottom: 2em;
      }
      a {
        display: block;
        height: 15px;
        width: 15px;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    &__up {
      display: flex;
      justify-content: flex-end;
      .up-button {
        height: 40px;
        width: 40px;
        background: #e4e9ec;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        color: $darkMedium;
        font-size: 1.6em;
      }
    }
  }
}
