.btn {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    padding: 1.35rem 4rem;
    font-size: 1.8rem;
    font-weight: 700;
    font-family: $textFont;
    border: none;
    outline: none;
    letter-spacing: 0.05rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $borderRadiusWidth;

    transition: all 0.3s;
  }

  &:disabled {
    opacity: 0.5;
    &:hover {
      cursor: default;
      transform: none;
      box-shadow: none;
    }
  }

  &:hover {
    cursor: pointer;
    transform: translateY(3px);
    box-shadow: 0 1.5rem 2.5rem rgba($colorBlack, 0.15);
  }

  // Different button colors
  &__primary {
    background-color: $primaryColor;
    color: $colorWhite !important;
  }

  &__white {
    background-color: $colorWhite;
    color: $primaryColor !important;
  }

  // Different button sizes
  &__small {
    // padding: 1rem 2rem !important;
    width: 7em;
    padding: 0.4em !important;
    font-size: 1em !important;
  }

  &__large {
    padding: 2rem 4rem !important;
  }

  &__wide {
    width: 100%;
  }
}
