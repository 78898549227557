.modal {
  position: fixed;
  z-index: 1002;
  background-color: white;
  width: 580px;
  padding: 5em;
  left: 0;
  top: 25%;
  right: 0;
  margin: 0 auto;
  border-radius: $borderRadiusWidth;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  @include responsive(phone) {
    top: 15%;
    width: 100%;
    border-radius: 0;
    padding: 3em;
  }

  &__close-icon {
    position: absolute;
    top: 1em;
    right: 1em;
    height: 30px;
    width: 30px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6em;
    cursor: pointer;
    color: $darkLight;
  }

  &__title {
    margin-bottom: 1.5em;
  }

  &__success-image {
    height: 300px;
    width: 100%;
  }
}
