.careers {
  @include responsive(tabletLandscape) {
    padding: 2em;
    text-align: center;
  }
  &__main {
    padding: 10em 0;
    h1 {
      margin-bottom: 1em;
      font-size: 3em;
      text-transform: capitalize;
    }
  }
  &__banner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    margin-bottom: 12em;

    @include responsive(tabletLandscape) {
      grid-template-columns: repeat(1, 1fr);
    }

    &-image {
      @include responsive(tabletLandscape) {
        grid-area: 1;
      }
      height: 400px;
      width: auto;
    }

    .btn {
      width: 20em;
    }

    p:not(:last-child) {
      margin-bottom: 2em;
    }
  }

  &__container {
    .open-role-header {
      font-size: 1.4em;
      margin-bottom: 1em;
      text-transform: uppercase;
      color: $primaryColor;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__depts {
    margin-top: 2em;
    font-size: 1.4em;
    & li {
      display: inline-block;
      margin-right: 2em;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 5em;

    @include responsive(tabletPortrait) {
      & thead {
        display: none;
      }
      &,
      & tbody,
      & th,
      & td,
      & tr {
        display: block;
      }
      & tr td:last-child {
        border: none;
        margin-bottom: 2em;
      }
      & th {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
      table td {
        /* Behave  like a "row" */
        border: none;
        position: relative;
        padding-bottom: 10px;
        padding-left: 50%;
        padding-top: 10px;
      }
      & td:before {
        /* Now like a table header */

        /* Top/left values mimic padding */
        top: 10px;
        left: 6px;
        text-align: left;
        width: 45%;
      }

      /*
       * Label the data
       */
      table tr {
        margin: 0;
      }
      table td {
        text-align: right;
      }
      & td:nth-of-type(n + 1):before {
        line-height: 19px;
        margin-right: 10px;
        float: left;
        text-align: left !important;
      }

      table td {
        text-align: right !important;
      }

      & td:nth-of-type(n + 1):after {
        clear: both;
      }
    }

    th,
    td {
      text-align: left;
      color: #666666;
      font-size: 1.4em;
      border-bottom: 1px solid #ddd;
      padding: 0.75em 0;
    }

    th {
      font-size: 1.2em;
      text-transform: uppercase;
    }

    td.role {
      font-weight: 700;
      color: #000;
      span.contract {
        display: block;
        font-weight: normal;
        font-size: 0.95em;
        color: #666666;
      }
    }
  }
}
